import { Link } from '@remix-run/react';
import { useCustomCheckout } from '@stripe/react-stripe-js';
import { $path } from 'remix-routes';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import type { GamePack } from '../../types/game';
import { getStaticAssetPath } from '../../utils/assets';
import { formatCurrency } from '../../utils/currency';
import { GamePackCoverImage } from '../GamePackCover/GamePackCoverPres';
import { Discount } from '../Payment/Discount';
import { ShowMore } from '../ShowMore';
import { InlineHeadcountEditor } from './OTPCheckoutHeadcount';

function InstantQuote() {
  const { total } = useCustomCheckout();

  return (
    <div className='bg-dark-gray rounded-lg px-4.5 text-white'>
      <div className='pt-4 pb-2 font-bold'>Your Instant Quote:</div>
      <div className='text-sms space-y-1'>
        <div className='flex items-baseline'>
          <div className='flex-1'>Subtotal</div>
          <div className='flex-none text-right tabular-nums'>
            {formatCurrency(total.subtotal / 100, true)}
          </div>
        </div>

        <Discount />
      </div>
      <div className='my-2.5 border-b border-secondary' />
      <div className='flex items-baseline font-bold'>
        <div className='flex-1'>Total Cost</div>
        <div className='flex-none text-right tabular-nums'>
          {formatCurrency(total.total / 100, true)}
        </div>
      </div>
      <div className='w-full text-right italic text-2xs py-2 text-icon-gray'>
        No hidden fees
      </div>
    </div>
  );
}

const sslBadge = getStaticAssetPath('images/checkout-ssl-badge.png');
const moneybackBadge = getStaticAssetPath(
  'images/checkout-moneyback-badge.png'
);
const gdprBadge = getStaticAssetPath('images/checkout-gdpr-badge.png');

function CheckoutBadges() {
  return (
    <div className='w-full flex items-center justify-center gap-2'>
      <img src={sslBadge} alt='SSL' className='w-[98px] h-auto' />
      <img src={moneybackBadge} alt='Moneyback' className='w-[74px] h-auto' />
      <img src={gdprBadge} alt='GDPR' className='w-[80px] h-auto' />
    </div>
  );
}

function DownloadW9() {
  const analytics = useOneTimePurchaseAnalytics();
  const trackDownload = useLiveCallback(() => {
    analytics.trackEventPurchaseW9Downloaded();
  });

  return (
    <div className='w-full flex justify-center'>
      <a
        href={getStaticAssetPath('pdfs/LunaPark-W9.pdf')}
        download
        className='font-bold text-icon-gray text-sms hover:underline'
        onClick={trackDownload}
      >
        Download our W-9
      </a>
    </div>
  );
}

export function OTPCheckoutSidebar(props: {
  pack: GamePack;
  headcount: number;
  onHeadcountChange: (headcount: number) => void;
  prePayment?: boolean;
}) {
  const { pack, headcount, prePayment, onHeadcountChange } = props;

  return (
    <div
      className='
        absolute top-0 right-0 w-75 2xl:w-85 h-full overflow-auto scrollbar bg-modal text-white
      '
    >
      <div className='mt-10 2xl:mt-14 w-full px-6 2xl:px-9 flex flex-col items-center'>
        <GamePackCoverImage
          pack={pack}
          alt={''}
          className='w-60 h-33 object-contain game-cover-clip'
        />
        <div className='w-60 text-sms text-white my-2'>
          <ShowMore lines={3}>{pack.description}</ShowMore>
        </div>
      </div>

      <div className='px-4.5 2xl:px-7.5 flex flex-col gap-2.5'>
        <InlineHeadcountEditor
          pack={pack}
          headcount={headcount}
          onHeadcountChange={onHeadcountChange}
        />
      </div>

      <div className='mt-7.5 px-4.5 2xl:px-7.5'>
        <InstantQuote />
      </div>

      {prePayment && (
        <div className='mt-4 text-sms font-medium text-center'>
          Ready to buy?{' '}
          <Link
            to={$path('/checkout/pay', window.location.search)}
            className='btn text-primary'
          >
            Checkout now!
          </Link>
        </div>
      )}

      <div className='mt-5 px-4.5 2xl:px-7.5 flex flex-col items-center gap-2'>
        <CheckoutBadges />
        <DownloadW9 />
      </div>
    </div>
  );
}
